export const FAVICON_URL =
  'https://s3.ap-northeast-2.amazonaws.com/www.fanc.io/favicon.ico';

export const VIDEO_URL =
  'https://s3.ap-northeast-2.amazonaws.com/www.fanc.io/static/media/alpha.05e86ec49bc72a690a52.mp4';

export const WHITEPAPER_ENG_URL =
  'https://s3.ap-northeast-2.amazonaws.com/www.fanc.io/static/media/fanC_Whitepaper_1.1_Eng.e6dd06feda4919e5554a.pdf';
export const WHITEPAPER_KOR_URL =
  'https://s3.ap-northeast-2.amazonaws.com/www.fanc.io/static/media/fanC_Whitepaper_1.1_Kor.e36cd85dd2784ef9730d.pdf';
